import React from "react";
import { Seo } from "../components/Seo";
import {graphql} from 'gatsby';
import PageBanner from "../components/PageBanner";
import clsx from "clsx";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Cta from "../components/Cta";
import Button from "../components/Button";

const Processpage = ({data}) => {
  const prismicData = data.prismicProcessPage.data;
  const pageBannerData = {
    title: 'Our Process',
    titleColor: '#ff6cbf',
    titleParts: prismicData.banner_one_title_parts.map((i,k) => ({
      color: i.color,
      text: i.part
    })),
    captionParts: prismicData.banner_one_caption_parts.map((i,k) => ({
      color: i.color,
      text: i.part
    })),
    paragraphParts: prismicData.body.map((i,k) => i.items),
    backgroundColor: prismicData.banner_one_background_color,
    image: prismicData.banner_one_image,
    imageStyle: 'mx-auto max-w-[65%] lg:max-w-[85%]',
    titlePartsSize: 'text-4xl lg:text-6xl',
    captionPartSize: 'text-2xl lg:text-4xl'
  }
  return (
    <main>
      <PageBanner {...pageBannerData} />
      <section style={{backgroundColor: prismicData.banner_two_background_color}}
        className="py-24">
        <div className="xl:container mx-auto px-6">
          <div className="grid lg:grid-cols-2 gap-y-6 md:gap-x-20 gap-x-60 w-full justify-center">
            <div className="mb-12 lg:mb-0">
              <h3 className="text-3xl lg:text-4xl font-black mb-8 md:mb-12 text-sourci-pink">What we do.</h3>
              <p className="text-5xl lg:text-6xl font-black mb-8">
                {prismicData.banner_two_title_parts.map((i,k) => (
                  <span style={{color: i.color}}
                    key={k}>{i.part} </span>
                ))}
              </p>
              <p className="text-3xl lg:text-4xl font-black mb-8 md:mb-12">
                {prismicData.banner_two_caption_parts.map((i,k) => (
                  <span style={{color: i.color}}
                    key={k}>{i.part} </span>
                ))}
              </p>
              {prismicData.body1.map((block, k) => (
                <p className="text-xl md:text-2xl mb-6"
                  key={block.id}>
                  {block.items.map((i,d) => (
                    <span style={{color: i.color}}
                      className={clsx(i.bold_text && 'font-bold')}
                      key={d}>
                        {i.text.text}
                    </span>
                  ))}
                </p>
              ))}
            </div>
            <div className="grid grid-cols-2 order-2 lg:order-1 gap-x-8">
              {prismicData.banner_two_image_grid.map((i, k) => (
                <div className="w-full"
                  key={k}>
                  <GatsbyImage image={getImage(i.image)}
                    alt={i.image.alt || ''}
                    className="mx-auto" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section style={{backgroundColor: prismicData.banner_three_background_color}}
        className="py-24">
          <div className="xl:container mx-auto px-6">
            <p className="text-3xl lg:text-4xl font-black mb-16 text-center">
              {prismicData.banner_three_title_parts.map((i,k) => (
                <span style={{color: i.color}}
                  key={k}
                  className={clsx(i.new_line && 'block')}>{i.part} </span>
              ))}
            </p>
            <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-8 items-start">
              {prismicData.body2.map((i,k) => (
                <div className="grid gap-y-8"
                  key={i.id}>
                  {i.items.map((i,d) => (
                    <div className="text-center px-6 py-4 border-4 border-white border-solid rounded-3xl"
                      key={d}>
                      <GatsbyImage image={getImage(i.image)}
                        alt={i.image.alt || ''}
                        className="mb-8" />
                      <p className="font-black text-xl mb-4"
                        style={{color: i.title_color}}>
                          {i.title}
                      </p>
                      <div className="text-black"
                        dangerouslySetInnerHTML={{__html: i.description.html}}></div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
      </section>

      <section className="bg-white py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl mb-6">And here's <span className="text-sourci-pink">how</span> we do it.</h3>
          <p className="text-3xl lg:text-4xl font-black text-sourci-teal mb-12">
            The four-point Sourci system to help you scale at speed.
          </p>
          <div className="hidden lg:block">
            <StaticImage src="../images/process_diagram.png"
              alt="Our 4 step process" />
          </div>
          <div className="lg:hidden grid md:grid-cols-2 gap-y-8 gap-x-4">
            <StaticImage src="../images/process_diagram_one.png"
              alt="Step one" />
            <StaticImage src="../images/process_diagram_two.png"
              alt="Step two" />
            <StaticImage src="../images/process_diagram_three.png"
              alt="Step three" />
            <StaticImage src="../images/process_diagram_four.png"
              alt="Step four" />
          </div>
        </div>
      </section>

      <Cta bg="sourci-teal"
        image={prismicData.cta_image}>
        <div className="text-center max-w-[900px] mx-auto">
          <p className="text-4xl md:text-5xl font-black mb-12 text-white">Partner with a growth team that's the <span className="text-black">driving force</span> behind today's most passionate eComm founders and pioneers.</p>
          <Button label="Let's talk."
            to="/contact"
            type="link"
            className="bg-sourci-teal hover:bg-sourci-teal-100 transition-colors text-white text-xl md:text-2xl font-black" />
        </div>
      </Cta>
    </main>
  )
}

export const query = graphql`
  query {
    prismicProcessPage {
      data {
        banner_one_background_color
        banner_one_caption_parts {
          color
          new_line
          part
        }
        banner_one_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        cta_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        banner_one_title_parts {
          color
          new_line
          part
        }
        banner_three_background_color
        banner_three_title_parts {
          color
          new_line
          part
        }
        banner_two_background_color
        banner_two_caption_parts {
          color
          new_line
          part
        }
        banner_two_image_grid {
          image {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
        }
        banner_two_title_parts {
          color
          new_line
          part
        }
        body {
          ... on PrismicProcessPageDataBodyParagraphBlocks {
            id
            items {
              bold_text
              color
              text {
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicProcessPageDataBody1ParagraphBlocks {
            id
            items {
              text {
                text
              }
              color
              bold_text
            }
          }
        }
        body2 {
          ... on PrismicProcessPageDataBody2ImageGridColumn {
            id
            items {
              description {
                html
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title
              title_color
            }
          }
        }
        meta_description
        meta_title
      }
    }
  }
`

export default Processpage;

export const Head = ({data}) => {
  const prismicData = data.prismicProcessPage.data;
  return (
    <Seo title={prismicData.meta_title}
      description={prismicData.meta_description}
      canonical="/process" />
  )
}